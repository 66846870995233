<template>
  <v-container fluid>
    <div>
      <h1 class="text-h4">{{ $lang.header.triggerRest }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row v-if="err || success || errUrlValidation || errPathVarValidation" dense no-gutters class="px-0">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
        <v-col v-if="errUrlValidation" cols="12">
          <v-alert color="error" :data-cy="errUrlValidation">{{ errUrlValidation }}</v-alert>
        </v-col>
        <v-col v-if="errPathVarValidation" cols="12">
          <v-alert color="error" :data-cy="errPathVarValidation">{{ errPathVarValidation }}</v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="pb-4">
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
          sm="12"
          md="8"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pb-6 fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="rest.name"
                          outlined
                          dense
                          :label="$lang.labels.name"
                          required
                          class="required-asterisk"
                          :rules="[v => !!v || 'Required!']"
                          :persistent-hint="isEdit"
                          :hint="formatHint"
                          :readonly="!userCanEdit"
                        ></v-text-field>
                        <v-btn
                          icon
                          color="primary"
                          class="ml-2"
                          style="margin-top: 2px"
                          @click="copyName()"
                        >
                          <v-icon>mdi-clipboard-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                      <v-btn
                        class="button-default-width"
                        color="primary"
                        :disabled="!userCanEdit"
                        @click="changeStatus()"
                      >
                        {{ rest.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters>
                    <v-col
                      cols="3"
                    >
                      <v-select
                        v-model="rest.method"
                        :items="formatMethods"
                        :label="$lang.labels.method"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="6"
                      class="px-sm-1"
                    >
                      <v-text-field
                        v-model="rest.url"
                        outlined
                        dense
                        :label="$lang.labels.url"
                        required
                        :rules="[v => !!v || 'Required!']"
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="2"
                      class="pr-sm-1"
                    >
                      <p
                        :class="{'pt-1 pl-1 clickable-simple': true, 'color-primary': rest.url, 'color-secondary': !rest.url}"
                        @click="rest.url ? copyUrl() : ''"
                      >
                        {{ $lang.actions.copyUrl }}
                      </p>
                    </v-col>
                    <v-col
                      cols="9"
                      sm="3"
                    >
                      <v-text-field
                        v-model="tempMaxRequestSize"
                        outlined
                        dense
                        :label="$lang.labels.maxRequestSize"
                        required
                        :rules="[v => !!v || 'Required!']"
                        class="required-asterisk"
                        :readonly="!userCanEdit"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="3"
                      class="px-sm-1"
                    >
                      <v-select
                        v-model="sizeUnit"
                        :items="sizeUnits"
                        :label="$lang.labels.unit"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="9"
                      class="pr-sm-1"
                    >
                      <v-autocomplete
                        v-model="rest.processId"
                        outlined
                        dense
                        :items="processes"
                        :loading="isLoadingProcesses"
                        :search-input.sync="searchProcesses"
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        :label="$lang.labels.process"
                        :placeholder="$lang.actions.startTyping"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        required
                        :rules="[v => !!v || 'Required!']"
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="2"
                      class="align-center"
                    >
                      <p
                        :class="{'pt-1 pl-1 clickable-simple': true, 'color-primary': rest.processId, 'color-secondary': !rest.processId}"
                        @click="rest.processId ? openProcess() : ''"
                      >
                        {{ $lang.actions.openProcess }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <p class="pa-0 ma-0 pt-2">{{ $lang.labels.comment }}</p>
                      <div class="mark-class pa-1 mb-2 mt-1 clickable-simple" @click="lock || !userCanEdit ? '' : showMarkdown = true" v-html="compiledMarkdown"></div>
                    </v-col>
                    <v-col cols="3" class="pl-1">
                      <p
                        class="pt-1 pt-6 clickable-simple color-primary"
                        @click="lock || !userCanEdit ? '' : showMarkdown = true"
                      >
                        {{ $lang.actions.edit }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-inline-flex"
                    >
                      <v-checkbox v-model="rest.basicAccessAuthentication" :label="$lang.labels.basicAccessAuthentication"></v-checkbox>
                      <v-checkbox v-model="rest.isGdprRelevant" class="pl-3" :label="$lang.labels.gdpr"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" class="pb-1">
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <h4 class="text-h5">{{ $lang.labels.validations }}</h4>
                    </v-col>
                    <v-col cols="12">
                      <template v-for="(rule, n) in rest.validationRules">
                        <v-row :key="validationKey + n" no-gutters align="center" class="py-1 pr-1">
                          <v-col cols="10" class="align-center pr-2">
                            <div class="d-inline-flex align-center" style="width: 100%">
                              <v-autocomplete
                                v-model="rule.validationRuleId"
                                outlined
                                dense
                                :items="validationRules"
                                :loading="isLoadingValidationRules"
                                hide-no-data
                                hide-details
                                clearable
                                item-text="name"
                                item-value="id"
                                :label="$lang.status[rule.type]"
                                :placeholder="$lang.actions.startTyping"
                                prepend-inner-icon="mdi-cloud-search-outline"
                                :readonly="!userCanEdit"
                                :data-cy="`rule-value-${n}`"
                                @change="validationKey = validationKey++"
                              ></v-autocomplete>
                              <p
                                style="margin-bottom: 0 !important;"
                                :class="{'m-0 p-0 pl-1 clickable-simple': true, 'color-primary': rule.validationRuleId, 'color-secondary': !rule.validationRuleId}"
                                @click="rule.validationRuleId ? openRule(rule.validationRuleId) : ''"
                              >
                                {{ $lang.actions.openRule }}
                              </p>
                              <p
                                style="margin-bottom: 0 !important;"
                                :class="{'m-0 p-0 pl-2 clickable-simple': true, 'color-primary': rule.validationRuleId, 'color-secondary': !rule.validationRuleId}"
                                @click="rule.validationRuleId = ''; validationKey = validationKey++"
                              >
                                {{ $lang.actions.clear }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col cols="12 py-1">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          color="secondary"
                          class="button-default-width"
                          :to="{ name: 'rest', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="rest.name"
                          :title="$lang.actions.delete"
                          type="rest"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'rest-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteRestFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'rest' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <v-btn
                        :disabled="!valid || lock"
                        color="success"
                        class="button-default-width"
                        @click="submit()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-floppy
                        </v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreRest($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="showMarkdown" v-model="showMarkdown" max-width="80%" width="80%">
        <markdown
          :markdown-data="rest.comment"
          @save="rest.comment = $event"
          @closeDialog="showMarkdown = false"
        />
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import Table from '@/components/ui/tables/HistoryDatatable'
import copy from 'copy-to-clipboard'
import Markdown from '@/components/ui/modals/Markdown'
import {
  getTriggerRestByIdUsingGET as getRest,
  disableTriggerRestUsingGET as disableRest,
  enableTriggerRestUsingGET as enableRest,
  updateTriggerRestUsingPUT as updateRest,
  createTriggerRestUsingPOST as createRest,
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess, getRolesUsingGET as getRoles,
  triggerRestCheckUsingGET as triggerRestCheck, getHistoriesUsingGET as getHistories,
  getSettingsUsingGET as getSettings,
  getValidationRulesUsingGET as getValidationRules,
  deleteRestTriggerUsingDELETE as deleteRestTrigger, deleteCronTriggerUsingDELETE as deleteCron
} from '@/utils/api'
import {
  predictBestSizeUnitFromBytesValue
} from '@/utils/helpers'
import { marked } from 'marked'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'

export default {
  components: {
    ActionButtonWithConfirmation,
    Table,
    Markdown,
    UserRolesSelect
  },
  data() {
    return {
      showMarkdown: false,
      validationKey: 10000,
      tab: 0,
      errUrlValidation: '',
      errPathVarValidation: '',
      err: '',
      success: '',
      valid: false,
      isEdit: false,
      loading: true,
      isLoadingProcesses: false,
      lock: false,
      rest: {
        createdOn: '',
        id: '',
        maxRequestSize: '',
        modifiedOn: '',
        name: '',
        comment: '',
        processId: '',
        status: '',
        method: '',
        url: '',
        isGdprRelevant: false,
        roles: [],
        validationRules: [],
        basicAccessAuthentication: false
      },
      methods: ['DELETE', 'GET', 'PATCH', 'POST', 'PUT'],
      processes: [],
      searchProcesses: '',
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      sizeUnits: ['Bytes', 'Kilobytes', 'Megabytes'],
      sizeUnit: 'Kilobytes',
      tempMaxRequestSize: '',
      isSuperUser: false,
      ruleTypes: ['BODY', 'HEADERS', 'QUERY_PARAMS', 'PATH_VARIABLES'],
      validationRules: [],
      isLoadingValidationRules: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false
    }
  },
  computed: {
    compiledMarkdown: function() {
      return marked.parse(this.rest?.comment) || ''
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatMethods() {
      return this.methods.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.rest.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.rest.modifiedOn)}, ID: ${this.rest.id}`
    }
  },
  watch: {
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'restEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'TRIGGER_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getRest({ id: this.$route.params.id })
            .then((res) => {
              this.rest = res.data.data

              this.editRolesIds = this.rest.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.rest.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.rest.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              if (this.rest.comment === null) {
                this.rest.comment = ''
              }

              this.formatSizeUnits()

              if (!this.rest.validationRules) this.rest.validationRules = []

              const tempValidationRules = []

              this.ruleTypes.forEach((rule, index) => {
                const tryFind = this.rest.validationRules.find((x) => x.type === rule)

                if (!tryFind) {
                  tempValidationRules.push({ type: rule, validationRuleId: '' })
                } else {
                  tempValidationRules.push({ type: rule, validationRuleId: tryFind.validationRule.id })
                }
              })

              this.rest.validationRules = tempValidationRules

              getProcess({ id: res.data.data.processId })
                .then((res) => {
                  this.processes = [res.data.data]

                  this.loading = false
                })
                .catch((err) => {
                  this.err = err
                })

            })
            .catch((err) => {
              this.err = err
            })
        } else {
          this.userCanEdit = true
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          if (this.$route.params.copy) {
            getRest({ id: this.$route.params.copy.id })
              .then((res) => {
                this.rest = res.data.data

                this.rest.name = this.$route.params.copy.name

                this.editRolesIds = this.rest.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
                this.useRolesIds = this.rest.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
                this.viewRolesIds = this.rest.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

                this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

                if (this.rest.comment === null) {
                  this.rest.comment = ''
                }

                this.formatSizeUnits()

                if (!this.rest.validationRules) this.rest.validationRules = []

                const tempValidationRules = []

                this.ruleTypes.forEach((rule, index) => {
                  const tryFind = this.rest.validationRules.find((x) => x.type === rule)

                  if (!tryFind) {
                    tempValidationRules.push({ type: rule, validationRuleId: '' })
                  } else {
                    tempValidationRules.push({ type: rule, validationRuleId: tryFind.validationRule.id })
                  }
                })

                this.rest.validationRules = tempValidationRules

                getProcess({ id: res.data.data.processId })
                  .then((res) => {
                    this.processes = [res.data.data]

                    this.loading = false
                  })
                  .catch((err) => {
                    this.err = err
                  })

              })
              .catch((err) => {
                this.err = err
              })
          } else {
            this.userCanEdit = true
            this.ruleTypes.forEach((rule) => {
              this.rest.validationRules.push({ type: rule, validationRuleId: '' })
            })
            this.loading = false
          }
        }
      })
  },
  methods: {
    copyName() {
      if (this.rest.name) {
        copy(this.rest.name)
        this.success = this.$lang.success.copiedClipboard
        setTimeout(() => this.success = '', 5000)
      } else {
        this.err = this.$lang.errors.nothingToCopy
        setTimeout(() => this.err = '', 5000)
      }
    },
    deleteRestFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteRestTrigger({ id: this.rest.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    async formatSizeUnits() {
      if (this.rest && this.rest.maxRequestSize) {
        const unitMaxRequestSize = await predictBestSizeUnitFromBytesValue(this.rest.maxRequestSize)

        this.sizeUnit = unitMaxRequestSize.unit
        this.tempMaxRequestSize = unitMaxRequestSize.value
      }
    },
    openProcess() {
      if (this.rest.processId) window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.rest.processId}`, '_blank')
    },
    openRule(rule) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/validations/edit/${rule}`, '_blank')
    },
    copyUrl() {

      const obj = {
        type: 'FRONTEND',
        status: 'ACTIVE',
        name: 'DEFAULT FRONTEND'
      }

      getSettings(obj)
        .then((res) => {
          if (res.data.data.meta.totalItems === 0) {
            this.err = this.$lang.errors.noFeSettings
            setTimeout(() => this.err = '', 5000)
          } else if (res.data.data.meta.totalItems > 1) {
            this.err = this.$lang.errors.multipleFeSettings
            setTimeout(() => this.err = '', 5000)
          } else if (res.data.data.meta.totalItems === 1) {
            // eslint-disable-next-line prefer-destructuring
            const data = res.data.data.items[0]
            const baseUrl = data.values.baseUrlRest
            const lastChar = baseUrl.slice(baseUrl.length - 1)
            const firstChar = this.rest.url ? this.rest.url.substring(0, 1) : ''

            const copyData = `${baseUrl}${lastChar === '/' ? '' : '/'}${firstChar === '/' ? this.rest.url.substring(1, this.rest.url.length) : this.rest.url}`

            if (copyData) {
              copy(copyData)

              this.success = this.$lang.success.copiedClipboard

              setTimeout(() => this.success = '', 5000)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchHistory(options) {
      if (!this.rest.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.rest.id
      obj.resourceType = 'TRIGGER_REST'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.err = err
        })
    },
    restoreRest(data) {
      this.rest = data

      if (!this.rest.comment) this.rest.comment = ''

      if (this.rest && this.rest.roles) {
        this.editRolesIds = this.rest.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.rest.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.rest.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }

      this.formatSizeUnits()

      getProcess({ id: this.rest.processId })
        .then((res) => {
          this.processes = [res.data.data]

          this.loading = false
        })
        .catch((err) => {
          this.err = err
        })
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
        getValidationRules()
          .then((res) => {
            this.validationRules = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async checkUrl() {
      this.err = ''
      this.errPathVarValidation = ''
      this.errUrlValidation = ''
      this.lock = true

      let res = null

      try {

        res = await triggerRestCheck({ method: this.rest.method, url: this.rest.url })

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || this.$lang.errors.check

          return false
        }

        if ((res.data.data.isExist && this.isEdit && this.rest.id !== res.data.data.triggerId) || (res.data.data.isExist && !this.isEdit)) {
          this.err = `${this.$lang.errors.checkIsExists} ${res.data.data.triggerId}`
          setTimeout(() => this.err = '', 5000)

          return false
        }

        return true

      } catch (err) {
        this.err = err
        setTimeout(() => this.err = '', 5000)

        return false
      }
    },
    checkUrlValidation() {
      const validationPresent = this.rest.validationRules[3].validationRuleId

      if (!validationPresent) return { areInValidation: [], areInUrl: [] }

      const pathVariableObject = this.validationRules.find((x) => x.id === validationPresent)

      const allValidationFields = pathVariableObject.fields.map((x) => x.name)

      const allUrlFields = this.rest.url.match(/[^{}]+(?=})/g)

      const areInValidation = allValidationFields.filter( ( el ) => {
        return !allUrlFields.includes( el )
      } )

      const areInUrl = allUrlFields.filter( ( el ) => {
        return !allValidationFields.includes( el )
      } )

      return { areInValidation, areInUrl }
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.err = err
        })
    },
    async submit() {
      this.err = ''

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.err = this.$lang.errors.editRoleCreate
        setTimeout(() => this.err = '', 5000)
      }

      this.lock = true

      const checkPassed = await this.checkUrl()

      if (!checkPassed) {
        this.lock = false

        return
      }

      const pathVariablesValidation = this.checkUrlValidation()

      const copyOfRest = JSON.parse(JSON.stringify(this.rest))

      let res = null

      const { id } = copyOfRest

      if (!this.isEdit) {
        delete copyOfRest.id
        copyOfRest.status = 'ACTIVE'
      }
      delete copyOfRest.createdOn
      delete copyOfRest.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      copyOfRest.roles = tempRoles

      copyOfRest.maxRequestSize = this.$options.filters.sizeUnitFormatter(this.tempMaxRequestSize, this.sizeUnit, 'Bytes')

      copyOfRest.validationRules = copyOfRest.validationRules.filter((x) => x.validationRuleId)

      try {

        res = this.isEdit ? await updateRest({ id, triggerRestRequest: copyOfRest }) : await createRest({ triggerRestRequest: copyOfRest })

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.restUpdate : this.$lang.errors.restCreate)
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.restUpdated : this.$lang.success.restCreated

        this.lock = false
        setTimeout(() => this.success = '', 5000)

        if (!this.isEdit) {
          this.rest = res.data.data
          this.isEdit = true
          this.$router.push({
            name: 'restEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

        if (pathVariablesValidation.areInUrl.length > 0 || pathVariablesValidation.areInValidation.length > 0) {
          const tempErrUrlString = pathVariablesValidation.areInUrl.join(', ')
          const tempErrPathString = pathVariablesValidation.areInValidation.join(', ')

          if (tempErrUrlString) {
            this.errUrlValidation = `${this.$lang.errors.triggerRestUrlValidation}: ${tempErrUrlString}`
            setTimeout(() => this.errUrlValidation = '', 30000)
          }
          if (tempErrPathString) {
            this.errPathVarValidation = `${this.$lang.errors.triggerRestPathVarValidation}: ${tempErrPathString}`
            setTimeout(() => this.errPathVarValidation = '', 30000)
          }
        }

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async changeStatus () {
      this.lock = true
      try {
        const res = this.rest.status === 'ACTIVE' ? await disableRest({ id: this.rest.id }) : await enableRest({ id: this.rest.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.restStatusUpdate
          setTimeout(() => this.err = '', 5000)
          this.lock = false
        }
        this.success = this.rest.status !== 'ACTIVE' ? this.$lang.success.restActivated : this.$lang.success.restDeactivated

        this.rest.status = res.data.data.status

        this.lock = false

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
